.hero {
  @apply block relative w-full h-full;

  &Image {
    @apply w-full;
  }

  &Background {
    @apply absolute top-1/2 left-1/2 m-0 p-0 h-full w-full;
    @apply text-white;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.3);
  }

  &Content {
    @apply absolute top-1/2 left-1/2 m-0 p-0 w-full text-white;
    transform: translate(-50%, -50%);

    h2 {
      @apply text-center leading-normal mb-4;
      @apply text-sm sm:text-sm md:text-7xl lg:text-7xl xl:text-7xl;
      @apply font-normal sm:font-normal md:font-extrabold lg:font-extrabold xl:font-extrabold;
      letter-spacing: 4px;
      text-shadow: 0 2px 8px rgb(0 0 0 / 15%);

      span {
        @apply inline-block;
        animation: 8s linear infinite text-animation;

        @keyframes text-animation {
          0% {
            transform: rotateX(0);
          }
          15% {
            transform: rotateX(0);
          }
          30% {
            transform: rotateX(0);
          }
          45% {
            transform: rotateX(0);
          }
          60% {
            transform: rotateX(0);
          }
          85% {
            transform: rotateX(0);
          }
          100% {
            transform: rotateX(360deg);
          }
        }
      }
    }

    p {
      @apply text-center italic leading-normal;
      @apply text-xs sm:text-xs md:text-base lg:text-base xl:text-base;
      @apply font-thin sm:font-thin md:font-thin lg:font-thin xl:font-thin;
      letter-spacing: 0.4px;
      text-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    }
  }
}
