.company {
  @apply px-5 mb-20;
}

.management {
  @apply py-10 mb-20;
  @apply bg-gray-100;
  @apply px-5 sm:px-5 md:px-5 lg:px-52 xl:px-52 2xl:px-52;
}

.greeting {
  @apply mb-20;
  @apply px-5 sm:px-5 md:px-5 lg:px-52 xl:px-52 2xl:px-52;

  &Image {
    @apply flex justify-center;
  }
}

.recruitment {
  @apply mb-20;
  @apply px-5 sm:px-5 md:px-5 lg:px-52 xl:px-52 2xl:px-52;

  &Content {
    @apply flex justify-center;

    &Levtech {
      border: 0;
      width: 320px;
      height: 60px;
    }
  }
}
