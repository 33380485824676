.navigation {
  @apply relative flex justify-between w-full;

  &Logo {
    @apply block py-3 pl-2 cursor-pointer;
  }

  &List {
    @apply list-none;
    @apply absolute block;
    @apply flex flex-nowrap flex-col items-stretch;
    @apply top-0 left-1/2 w-full h-full pl-0 py-10;
  }

  &Open {
    animation: 0.6s ease-out 0s 1 both drawerIn;
    @keyframes drawerIn {
      0% {
        transform: translate(-50%, -100vh);
      }
      to {
        transform: translate(-50%);
      }
    }
  }

  &Close {
    animation: 0.4s ease-in 0s 1 both drawerOut;
    @keyframes drawerOut {
      0% {
        transform: translate(-50%);
      }
      to {
        transform: translate(-50%, -100vh);
      }
    }
  }

  &Toggle {
    @apply flex justify-center items-center cursor-pointer;
    @apply h-10 w-10 pr-5 pt-2;
    @apply border-0;
    @apply bg-transparent;
    z-index: 9998;

    &Open {
      @apply block absolute rounded-full;

      &:before {
        @apply block relative rounded-full h-1 bg-blue-900 bottom-3 origin-top-left;
        content: "";
        width: 46.5px;
        transform: translate(5px) rotate(40.7deg);
        transition: 0.3s;
      }

      &:after {
        @apply block relative rounded-full h-1 bg-blue-900 top-3 origin-bottom-left;
        content: "";
        width: 46.5px;
        transform: translate(5px, 1px) rotate(-40.7deg);
        transition: 0.3s;
      }
    }

    &Close {
      @apply block absolute rounded-full h-1 w-10 bg-blue-900;
      content: "";
      transition: 0.3s;

      &:before {
        @apply block absolute rounded-full h-1 w-10 top-3 bg-blue-900;
        content: "";
        transition: 0.3s;
      }

      &:after {
        @apply block absolute rounded-full h-1 w-10 bottom-3 bg-blue-900;
        content: "";
        transition: 0.3s;
      }
    }
  }
}
