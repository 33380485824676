.title {
  @apply block relative w-full h-full;
  @apply mt-14 sm:mt-14 md:mt-14 lg:mt-0 xl:mt-0 2xl:mt-0;

  &Content {
    @apply absolute text-white top-1/2 left-1/2 m-0 p-0 w-full;
    @apply text-2xl sm:text-2xl md:text-2xl lg:text-5xl xl:text-5xl 2xl:text-5xl;
    transform: translate(-50%, -50%);

    & > h1 {
      @apply text-center font-extrabold mb-4;
      letter-spacing: 4px;
      line-height: 1.3;
      text-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    }
  }
}
