.recruitment {
  @apply no-underline mr-0;
  @apply w-96 sm:w-96 md:w-96 lg:w-500px xl:w-500px 2xl:w-500px;

  &Header {
    @apply relative;
    @apply text-center text-white;
    @apply bg-amber-600;
    @apply w-96 sm:w-96 md:w-96 lg:w-500px xl:w-500px 2xl:w-500px;

    &Engage {
      @apply absolute;
      @apply right-2 top-1;
    }

    &Image {
      @apply absolute;
      @apply w-96 sm:w-96 md:w-96 lg:w-500px xl:w-500px 2xl:w-500px;
    }

    &Area {
      @apply relative flex justify-start;
      @apply -bottom-24 left-4;

      &Logo {
        @apply bg-white p-3 mr-2;
        @apply border-8 border-white;
        box-shadow: 0 3px 8px rgb(0 0 0 / 15%);
      }

      p {
        @apply text-white text-sm font-extralight;
        @apply pt-1;
      }
    }
  }

  &Catch {
    @apply px-5 mb-3;
    @apply leading-tight text-lg font-medium;
    color: #333333;
    margin-top: 110px;
  }

  &Footer {
    @apply bg-gray-100 py-2 px-3;
    @apply border-t border-t-gray-200;
    @apply flex justify-end;

    button {
      @apply py-2 px-4 cursor-pointer;
      @apply bg-white;
      @apply text-center text-xs;
      @apply border rounded;
      border-color: #E6870C;
      color: #E6870C;
    }
  }
}
